<template>
  <div class="start-window">
    <template v-if="!sent">
      <div class="b21">{{ $t('AUTH_FORGOT_RECOVERY') }}</div>

      <div class="b12">
        {{ $t('AUTH_FORGOT_RESET') }}
      </div>
      <form action>
        <label for="t1" class="text">
          <span>{{ $t('AUTH_COMMON_EMAIL') }}</span>
          <input
            type="text"
            class="inp-st"
            id="t1"
            placeholder
            v-model="email"
            tabindex="1"
          />
        </label>

        <button class="btn-st-bg" @click.prevent.stop="forgot">
          {{ $t('AUTH_COMMON_CONTINUE') }}
        </button>
      </form>
    </template>
    <template v-if="sent">
      <p>{{ $t('AUTH_FORGOT_SENT') }}</p>
    </template>
    <p class="login-alerts" v-if="loginAlert">
      {{ loginAlert }}
    </p>
  </div>
</template>
<script>
import { AUTH_FORGOT } from "@/store/actions/auth";
import { mapGetters } from "vuex";
import PageUtils from "@/models/page-utils";
export default {
  name: "Forgot",
  computed: {
    ...mapGetters(["authStatus"]),
  },
  data() {
    return {
      email: "",
      sent: false,
      loginAlert: "",
    };
  },
  methods: {
    async forgot() {
      this.loginAlert = "";
      const isValidEmail = PageUtils.isValidEmail(this.email);
      if (isValidEmail) {
        try {
          await this.$store.dispatch(AUTH_FORGOT, { email: this.email });
          this.$store.commit("RESET_STORE");
          this.sent = true;
        } catch (err) {
          this.loginAlert = this.$t(err.code);
          }
        } else {
          this.loginAlert = this.$t('AUTH_COMMON_VALID_EMAIL');
      }
    },
  },
};
</script>
